import React from "react";
import socketio from "socket.io-client";

const user = JSON.parse(localStorage.getItem("userInfo"));

// Create socket connection with or without extraHeaders
export const socket = user
    ? socketio.connect("https://dev-api.surrogacy-platform.com/", {
        extraHeaders: {
            Authorization: `Token ${user.token}`,
            "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
        },
    })
    : socketio.connect("https://dev-api.surrogacy-platform.com/", {
        extraHeaders: {
            "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
        },
    });

export const SocketContext = React.createContext();