import { put, takeLatest, call } from 'redux-saga/effects';
import {
  fetchEmailsSuccess,
  fetchEmailsFailure,
  fetchMessagesForEmailSuccess,
  fetchMessagesForEmailFailure,
  updateEmailNotificationSuccess,
  updateEmailNotificationFailure,
  sendMailSuccess,
  sendMailFailure,
  receiveConversationResponse,
  handleConversationError,
  getEmailsSentSuccess,
  getEmailsSentFailure,
  fetchStarSuccess,
  fetchStarFailure,
  deleteConversationSuccess,
  deleteConversationFailure,
  addEmailLabelSuccess,
  addEmailLabelFailure,
  getEmailsStarredSuccess,
  getEmailsStarredFailure,
  getEmailsDeletedSuccess,
  getEmailsDeletedFailure,
  deleteEmailsLabelsSuccess,
  deleteEmailsLabelsFailure,
} from '../actions/emailActions';
import instance from "../api/api";
import {
  ADD_EMAIL_LABEL_REQUEST,
  DELETE_CONVERSATION_REQUEST,
  DELETE_EMAILS_LABELS_REQUEST,
  FETCH_EMAILS_REQUEST,
  FETCH_MESSAGES_FOR_EMAIL_REQUEST,
  FETCH_STAR_REQUEST,
  GET_EMAILS_DELETED_REQUEST,
  GET_EMAILS_SENT_REQUEST,
  GET_EMAILS_STARRED_REQUEST,
  SEND_CONVERSATION_REQUEST,
  SEND_MAIL_REQUEST,
  UPDATE_EMAIL_NOTIFICATION_REQUEST
} from "../constants/actionTypes";


function* fetchEmailsSaga(action) {
  try {
    let url = `messages/emails-inbox?page=${action.payload.page}&limit=${action.payload.limit}`;
    if (action.payload.category) {
      url += `&category=${action.payload.category}`;
    }
    const response = yield call(instance.get, url);
    yield put(fetchEmailsSuccess(response.data));
    if (action.callback) {
      action.callback(response.data);
    }
  } catch (error) {
    yield put(fetchEmailsFailure(error.message));
    if (action.callback) {
      action.callback(error.message);
    }
  }
}

function* fetchMessagesForEmailSaga(action) {
  try {
    const { page, limit, mailId } = action.payload;
    const response = yield call(instance.get, `messages/messages-client-by-mail-id/${mailId}?page=${page}&limit=${limit}`);
    yield put(fetchMessagesForEmailSuccess(response.data))
  } catch (error) {
    yield put(fetchMessagesForEmailFailure(error.message));
  }
}

function* fetchStar(action) {
  try {
    const response = yield call(instance.post, `conversation/star/${action.payload}`);
    yield put(fetchStarSuccess(response.data));
    if (action.callback) {
      action.callback(response.data);
    }
  } catch (error) {
    yield put(fetchStarFailure(error));
    if (action.callback) {
      action.callback(null, error);
    }
  }
}

function* sendMailSaga(action) {
  try {
    const response = yield call(() => instance.post('messages', action.payload));
    yield put(sendMailSuccess(response));
    if (action.callback) {
      action.callback(response);
    }
  } catch (error) {
    yield put(sendMailFailure(error));
    if (action.callback) {
      action.callback(null, error);
    }
  }
}

function* updateEmailNotificationSaga(action) {
  try {
    const response = yield call(() => instance.patch('conversation/updateEmailNotify', { mailIds: action.mailId }));
    yield put(updateEmailNotificationSuccess(action.mailId));
  } catch (error) {
    yield put(updateEmailNotificationFailure(error.message));
  }
}

function* getEmailsSent(action) {
  try {
    let url = `messages/emails-sent?page=${action.payload.page}&limit=${action.payload.limit}`;
    if (action.payload.category) {
      url += `&category=${action.payload.category}`;
    }
    const response = yield call(instance.get, url);
    yield put(getEmailsSentSuccess(response.data));
    if (action.callback) {
      action.callback(response.data);
    }
  } catch (error) {
    yield put(getEmailsSentFailure(error.message));
    if (action.callback) {
      action.callback(error.message);
    }
  }
}

function* deleteConversation(action) {
  try {
    const response = yield call(instance.delete, `conversation/delete?ids=[${action.payload}]`);
    yield put(deleteConversationSuccess(response.data));
    if (action.callback) {
      action.callback(response.data);
    }
  } catch (error) {
    yield put(deleteConversationFailure(error));
    if (action.callback) {
      action.callback(error);
    }
  }
}

function* addEmailLabel(action) {
  try {
    const response = yield call(instance.patch, 'conversation/addCategory', action.payload);
    yield put(addEmailLabelSuccess(response.data));
    if (action.callback) {
      action.callback(response.data);
    }
  } catch (error) {
    yield put(addEmailLabelFailure(error));
    if (action.callback) {
      action.callback(error);
    }
  }
}

function* getEmailsStarred(action) {
  try {
    let url = `messages/liked-emails?page=${action.payload.page}&limit=${action.payload.limit}`;
    if (action.payload.category) {
      url += `&category=${action.payload.category}`;
    }
    const response = yield call(instance.get, url);
    yield put(getEmailsStarredSuccess(response.data));
    if (action.callback) {
      action.callback(response.data);
    }
  } catch (error) {
    yield put(getEmailsStarredFailure(error.message));
    if (action.callback) {
      action.callback(error.message);
    }
  }
}

function* getEmailsDeleted(action) {
  try {
    let url = `messages/deleted-emails?page=${action.payload.page}&limit=${action.payload.limit}`;
    if (action.payload.category) {
      url += `&category=${action.payloadaction.payload.category}`;
    }
    const response = yield call(instance.get, url);
    yield put(getEmailsDeletedSuccess(response.data));
    if (action.callback) {
      action.callback(response.data);
    }
  } catch (error) {
    yield put(getEmailsDeletedFailure(error.message));
    if (action.callback) {
      action.callback(error.message);
    }
  }
}

function* deleteEmailsLabels(action) {
  try {
    const response = yield call(instance.delete, `conversation/deleteCategory/[${action.payload}]`);
    yield put(deleteEmailsLabelsSuccess(response.data));
    if (action.callback) {
      action.callback(response.data);
    }
  } catch (error) {
    yield put(deleteEmailsLabelsFailure(error));
    if (action.callback) {
      action.callback(error);
    }
  }
}

function* emailSaga() {
  yield takeLatest(FETCH_EMAILS_REQUEST, fetchEmailsSaga);
  yield takeLatest(FETCH_MESSAGES_FOR_EMAIL_REQUEST, fetchMessagesForEmailSaga);
  yield takeLatest(SEND_MAIL_REQUEST, sendMailSaga);
  yield takeLatest(UPDATE_EMAIL_NOTIFICATION_REQUEST, updateEmailNotificationSaga);
  yield takeLatest(GET_EMAILS_SENT_REQUEST, getEmailsSent);
  yield takeLatest(FETCH_STAR_REQUEST, fetchStar);
  yield takeLatest(DELETE_CONVERSATION_REQUEST, deleteConversation);
  yield takeLatest(ADD_EMAIL_LABEL_REQUEST, addEmailLabel);
  yield takeLatest(GET_EMAILS_STARRED_REQUEST, getEmailsStarred);
  yield takeLatest(GET_EMAILS_DELETED_REQUEST, getEmailsDeleted);
  yield takeLatest(DELETE_EMAILS_LABELS_REQUEST, deleteEmailsLabels);
}

export default emailSaga;







