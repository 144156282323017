import { createContext, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

const SEOContext = createContext();

export function SEOProvider({ children }) {
    const [seoData, setSeoData] = useState({
        title: 'Surrogacy Platform - Find Egg Donors and Surrogacy services Open Database',
        description: 'Surrogacy Platform - a service with an open database of egg donors and implementation of full Surrogacy Programs in Cyprus. List of egg donors with pictures and surrogacy services',
    });

    const setSEO = (newData) => {
        setSeoData((prevData) => ({
            ...prevData,
            ...newData,
        }));
    };

    return (
        <SEOContext.Provider value={{ seoData, setSEO }}>
            <Helmet>
                <title>{seoData?.title || 'Default Title'}</title>
                <meta name="description" content={seoData?.description || 'Default Description'} />
            </Helmet>
            {children}
        </SEOContext.Provider>
    );
}

export function useSEO() {
    return useContext(SEOContext);
}
