import {
  SUBMIT_CONTACT_FORM_REQUEST,
  SUBMIT_CONTACT_FORM_SUCCESS,
  SUBMIT_CONTACT_FORM_FAILURE, 
  FETCH_USER_BY_ID_REQUEST, 
  FETCH_USER_FAILURE, 
  FETCH_USER_BY_ID_SUCCESS, 
  UPDATE_USER_INFO_REQUEST, 
  UPDATE_USER_INFO_SUCCESS, 
  UPDATE_USER_INFO_FAILURE,
  UPLOAD_USER_AVATAR_REQUEST,
  UPLOAD_USER_AVATAR_SUCCESS,
  UPLOAD_USER_AVATAR_FAILURE,
  GET_CLIENT_ORDER_DONOR_REQUEST,
  GET_CLIENT_ORDER_DONOR_SUCCESS,
  GET_CLIENT_ORDER_DONOR_FAILURE,
  GET_LAST_ORDER_COINS_REQUEST,
  GET_LAST_ORDER_COINS_SUCCESS,
  GET_LAST_ORDER_COINS_FAILURE,
  GET_USER_UNREADED_MESS_REQUEST,
  GET_CLIENT_BALANCE_REQUEST,
  GET_CLIENT_BALANCE_SUCCESS,
  GET_CLIENT_BALANCE_FAILURE,
} from '../constants/actionTypes';
  
  const initialState = {
    user: null,
    loading: false,
    error: null,
    orderDonorInfo: null,
    lastOrderCoinPack: null,
    unreaded: null,
    balance: null
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case SUBMIT_CONTACT_FORM_REQUEST:
        // Обработка запроса
        return state;
  
      case SUBMIT_CONTACT_FORM_SUCCESS:
        // Обработка успешного запроса
        return state;
  
      case SUBMIT_CONTACT_FORM_FAILURE:
        // Обработка ошибки
        return state;

      case FETCH_USER_BY_ID_REQUEST:
        return { ...state, loading: true, error: null };

      case FETCH_USER_BY_ID_SUCCESS:
        return { ...state, loading: false, user: action.payload, error: null };

      case FETCH_USER_FAILURE:
        return { ...state, loading: false, error: action.payload };

      case UPDATE_USER_INFO_REQUEST:
        return { ...state, loading: true, error: null };
  
      case UPDATE_USER_INFO_SUCCESS:
        return { ...state, loading: false, user: { ...state.user, ...action.payload }, error: null };
  
      case UPDATE_USER_INFO_FAILURE:
        return { ...state, loading: false, error: action.payload };

      case UPLOAD_USER_AVATAR_REQUEST:
        return { ...state, loading: true, error: null };
  
      case UPLOAD_USER_AVATAR_SUCCESS:
        return { ...state, loading: false, error: null };
  
      case UPLOAD_USER_AVATAR_FAILURE:
        return { ...state, loading: false, error: action.payload };
      
      case GET_CLIENT_ORDER_DONOR_REQUEST:
        return { ...state, loading: true, error: null };
  
      case GET_CLIENT_ORDER_DONOR_SUCCESS:
        return { ...state, loading: false, orderDonorInfo: action.payload, error: null };
  
      case GET_CLIENT_ORDER_DONOR_FAILURE:
        return { ...state, loading: false, error: action.payload };

      case GET_LAST_ORDER_COINS_REQUEST:
        return { ...state, loading: true, error: null };
  
      case GET_LAST_ORDER_COINS_SUCCESS:
        return { ...state, loading: false, lastOrderCoinPack: action.payload, error: null };
  
      case GET_LAST_ORDER_COINS_FAILURE:
        return { ...state, loading: false, error: action.payload };

      case GET_CLIENT_BALANCE_REQUEST:
        return { ...state, loading: true, error: null };
  
      case GET_CLIENT_BALANCE_SUCCESS:
        return { ...state, loading: false, balance: action.payload, error: null };
  
      case GET_CLIENT_BALANCE_FAILURE:
        return { ...state, loading: false, error: action.payload };
  
      case GET_USER_UNREADED_MESS_REQUEST:
        return { ...state, loading: false, unreaded: action.payload, error: null };
  
      default:
        return state;

    }
  };
  
  export default userReducer;
  