import {
  FETCH_DONORS_REQUEST,
  FETCH_DONORS_SUCCESS,
  FETCH_DONORS_FAILURE,
  SET_FILTER_RESPONSE,
  FETCH_DONOR_BY_ID_REQUEST,
  FETCH_DONOR_BY_ID_SUCCESS,
  FETCH_DONOR_BY_ID_FAILURE,
  FETCH_NEW_DONORS_REQUEST,
  FETCH_NEW_DONORS_SUCCESS,
  FETCH_NEW_DONORS_FAILURE,
  ADD_DONOR_TO_SAVED_REQUEST,
  ADD_DONOR_TO_SAVED_SUCCESS,
  ADD_DONOR_TO_SAVED_FAILURE,
  GET_SAVED_DONORS_REQUEST,
  GET_SAVED_DONORS_SUCCESS,
  GET_SAVED_DONORS_FAILURE,
  BUY_DONOR_PHOTO_REQUEST,
  BUY_DONOR_PHOTO_SUCCESS,
  BUY_DONOR_PHOTO_FAILURE,
  GET_DONOR_PHOTO_REQUEST,
  GET_DONOR_PHOTO_SUCCESS,
  GET_DONOR_PHOTO_FAILURE,
  FETCH_LIKED_DONORS_REQUEST,
  FETCH_LIKED_DONORS_SUCCESS,
  FETCH_LIKED_DONORS_FAILURE,
  FETCH_SAVED_DONORS_REQUEST,
  FETCH_SAVED_DONORS_SUCCESS,
  FETCH_SAVED_DONORS_FAILURE,
  GET_DONOR_VIDEO_REQUEST,
  GET_DONOR_VIDEO_SUCCESS,
  GET_DONOR_VIDEO_FAILURE,
  SET_SPERM_DONORS,
  SET_SURROGACY_DONORS,
  DONOR_APPLICATION_REQUEST,
  DONOR_APPLICATION_SUCCESS,
  DONOR_APPLICATION_FAILURE,
  DONOR_FULL_REGISTRATION_REQUEST,
  DONOR_FULL_REGISTRATION_SUCCESS,
  DONOR_FULL_REGISTRATION_FAILURE,
  UPLOAD_DONOR_PHOTOS_REQUEST,
  UPLOAD_DONOR_PHOTOS_SUCCESS,
  UPLOAD_DONOR_PHOTOS_FAILURE,
} from '../constants/actionTypes';

export const fetchDonorsRequest = (page, limit, sortType, donorType, filterParams) => ({
  type: FETCH_DONORS_REQUEST,
  payload: { page, limit, sortType, donorType, filterParams },
});

export const fetchDonorsSuccess = (list) => ({
  type: FETCH_DONORS_SUCCESS,
  payload: list,
});

export const fetchDonorsFailure = (error) => ({
  type: FETCH_DONORS_FAILURE,
  payload: error,
});

export const fetchLikedDonorsRequest = (page, limit, sortType) => ({
  type: FETCH_LIKED_DONORS_REQUEST,
  payload: { page, limit, sortType },
});

export const fetchLikedDonorsSuccess = (list) => ({
  type: FETCH_LIKED_DONORS_SUCCESS,
  payload: list,
});

export const fetchLikedDonorsFailure = (error) => ({
  type: FETCH_LIKED_DONORS_FAILURE,
  payload: error,
});

export const fetchSavedDonorsRequest = (page, limit, sortType) => ({
  type: FETCH_SAVED_DONORS_REQUEST,
  payload: { page, limit, sortType },
});

export const fetchSavedDonorsSuccess = (list) => ({
  type: FETCH_SAVED_DONORS_SUCCESS,
  payload: list,
});

export const fetchSavedDonorsFailure = (error) => ({
  type: FETCH_SAVED_DONORS_FAILURE,
  payload: error,
});

export const setFilterResponse = (response) => ({
  type: SET_FILTER_RESPONSE,
  payload: response,
});

export const setSurrogacyDonors = (response) => ({
  type: SET_SURROGACY_DONORS,
  payload: response,
});

export const setSpermDonors = (response) => ({
  type: SET_SPERM_DONORS,
  payload: response,
});

export const fetchDonorByIdRequest = (id) => ({
  type: FETCH_DONOR_BY_ID_REQUEST,
  payload: { id },
});

export const fetchDonorByIdSuccess = (donor) => ({
  type: FETCH_DONOR_BY_ID_SUCCESS,
  payload: donor,
});

export const fetchDonorByIdFailure = (error) => ({
  type: FETCH_DONOR_BY_ID_FAILURE,
  payload: error,
});

export const fetchNewDonorsRequest = (page, limit) => ({
  type: FETCH_NEW_DONORS_REQUEST,
  payload: { page, limit },
});

export const fetchNewDonorsSuccess = (list) => ({
  type: FETCH_NEW_DONORS_SUCCESS,
  payload: list,
});

export const fetchNewDonorsFailure = (error) => ({
  type: FETCH_NEW_DONORS_FAILURE,
  payload: error,
});

export const addDonorToSavedRequest = (donorId) => ({
  type: ADD_DONOR_TO_SAVED_REQUEST,
  payload: donorId,
});

export const addDonorToSavedSuccess = () => ({
  type: ADD_DONOR_TO_SAVED_SUCCESS
});

export const addDonorToSavedFailure = (error) => ({
  type: ADD_DONOR_TO_SAVED_FAILURE,
  payload: error,
});

export const getSavedDonorsRequest = (callback) => ({
  type: GET_SAVED_DONORS_REQUEST,
  callback
});

export const getSavedDonorsSuccess = (data) => ({
  type: GET_SAVED_DONORS_SUCCESS,
  payload: data
});

export const getSavedDonorsFailure = (error) => ({
  type: GET_SAVED_DONORS_FAILURE,
  payload: error,
});

export const getDonorPhotosRequest = (donorId) => ({
  type: GET_DONOR_PHOTO_REQUEST,
  payload: {donorId}
});

export const getDonorPhotosSuccess = (donorId, data) => ({
  type: GET_DONOR_PHOTO_SUCCESS,
  payload: {donorId, data}
});

export const getDonorPhotosFailure = (error) => ({
  type: GET_DONOR_PHOTO_FAILURE,
  payload: error,
});

export const getDonorVideosRequest = (donorId) => ({
  type: GET_DONOR_VIDEO_REQUEST,
  payload: {donorId}
});

export const getDonorVideosSuccess = (donorId, data) => ({
  type: GET_DONOR_VIDEO_SUCCESS,
  payload: {donorId, data}
});

export const getDonorVideosFailure = (error) => ({
  type: GET_DONOR_VIDEO_FAILURE,
  payload: error,
});

export const buyDonorPhotoRequest = (payload, callback) => ({
  type: BUY_DONOR_PHOTO_REQUEST,
  payload, callback
});

export const buyDonorPhotoSuccess = (data) => ({
  type: BUY_DONOR_PHOTO_SUCCESS,
  payload: data
});

export const buyDonorPhotoFailure = (error) => ({
  type: BUY_DONOR_PHOTO_FAILURE,
  payload: error,
});

export const donorApplicationRequest = (payload, callback) => ({
  type: DONOR_APPLICATION_REQUEST,
  payload, callback
});

export const donorApplicationSuccess = (data) => ({
  type: DONOR_APPLICATION_SUCCESS,
  payload: data
});

export const donorApplicationFailure = (error) => ({
  type: DONOR_APPLICATION_FAILURE,
  payload: error,
});

export const donorFullRegistrationRequest = (payload, callback) => ({
  type: DONOR_FULL_REGISTRATION_REQUEST,
  payload, callback
});

export const donorFullRegistrationSuccess = (data) => ({
  type: DONOR_FULL_REGISTRATION_SUCCESS,
  payload: data
});

export const donorFullRegistrationFailure = (error) => ({
  type: DONOR_FULL_REGISTRATION_FAILURE,
  payload: error,
});

export const uploadDonorPhotosRequest = (payload, callback) => ({
  type: UPLOAD_DONOR_PHOTOS_REQUEST,
  payload, callback
});

export const uploadDonorPhotosSuccess = (data) => ({
  type: UPLOAD_DONOR_PHOTOS_SUCCESS,
  payload: data
});

export const uploadDonorPhotosFailure = (error) => ({
  type: UPLOAD_DONOR_PHOTOS_FAILURE,
  payload: error,
});

