import { LOGIN_SUCCESS, LOGIN_FAILURE, REGISTRATION_SUCCESS, REGISTRATION_FAILURE } from '../constants/actionTypes';

const initialState = {
    user: null,
    error: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return { ...state, user: action.payload, error: null }; // Обновление состояния при успешном входе
        case LOGIN_FAILURE:
            return { ...state, user: null, error: action.payload };
        case REGISTRATION_SUCCESS:
            return { ...state, user: action.payload, error: null }; // Обновление состояния при успешном входе
        case REGISTRATION_FAILURE:
            return { ...state, user: null, error: action.payload };
        default:
            return state;
    }
};

export default authReducer;
