import { put, takeLatest } from "redux-saga/effects";
import { setIsShowModal } from "../actions/globalActions";
import { setPopupActive, setPopupClose } from "../actions/modalActions";
import { FETCH_MODAL_CLOSE, FETCH_POPUP } from "../constants/actionTypes";

export function* handleFetchPopup(action) {
    try {
        document.querySelector('body').style.overflow = 'hidden';
        yield put(setPopupActive({ ...action.payload, isOpen: true }));
        yield put(setIsShowModal(true));
    } catch (error) {
        console.error(error);
    }
}

export function* handleClosePopup() {
    try {
        document.querySelector('body').style.overflow = 'initial';
        yield put(setIsShowModal(false));
    } catch (error) {
        console.error(error);
    }
}

export default function* modalSaga() {
    yield takeLatest(FETCH_POPUP, handleFetchPopup);
    yield takeLatest(FETCH_MODAL_CLOSE, handleClosePopup);
}
