import { FETCH_CHATS_FAILURE, FETCH_CHATS_REQUEST, FETCH_CHATS_SUCCESS, FETCH_MESSAGES_FAILURE, FETCH_MESSAGES_REQUEST, FETCH_MESSAGES_SUCCESS, FETCH_NOTIFICATIONS_FAILURE, FETCH_NOTIFICATIONS_REQUEST, FETCH_NOTIFICATIONS_SUCCESS, FETCH_SUPPORT_CHATS_FAILURE, FETCH_SUPPORT_CHATS_ID_SUCCESS, FETCH_SUPPORT_CHATS_REQUEST, FETCH_SUPPORT_CHATS_SUCCESS, FETCH_SUPPORT_NOTIFICATIONS_FAILURE, FETCH_SUPPORT_NOTIFICATIONS_REQUEST, FETCH_SUPPORT_NOTIFICATIONS_SUCCESS, RESET_MESSAGES, UPDATE_NOTIFICATION_FAILURE, UPDATE_NOTIFICATION_REQUEST, UPDATE_NOTIFICATION_SUCCESS, UPDATE_SUPPORT_NOTIFICATION_FAILURE, UPDATE_SUPPORT_NOTIFICATION_REQUEST, UPDATE_SUPPORT_NOTIFICATION_SUCCESS } from "../constants/actionTypes";


export const fetchChatsRequest = (payload) => ({
  type: FETCH_CHATS_REQUEST,
  payload,
});

export const fetchChatsSuccess = (data) => ({
  type: FETCH_CHATS_SUCCESS,
  payload: data,
});

export const fetchChatsFailure = (error) => ({
  type: FETCH_CHATS_FAILURE,
  payload: error,
});

export const fetchSupportChatsRequest = (payload) => ({
  type: FETCH_SUPPORT_CHATS_REQUEST,
  payload,
});

export const fetchSupportChatsSuccess = (data) => ({
  type: FETCH_SUPPORT_CHATS_SUCCESS,
  payload: data,
});
export const fetchSupportChatsIdSuccess = (id) => ({
  type: FETCH_SUPPORT_CHATS_ID_SUCCESS,
  payload: id,
});

export const fetchSupportChatsFailure = (error) => ({
  type: FETCH_SUPPORT_CHATS_FAILURE,
  payload: error,
});

export const fetchMessagesRequest = (chatId, page, limit) => {
  return {
    type: FETCH_MESSAGES_REQUEST,
    chatId,
    page,
    limit,
  };
};

export const fetchMessagesSuccess = (chatId, messages, totalMessagesCount) => {
  return {
    type: FETCH_MESSAGES_SUCCESS,
    payload: {chatId, messages, totalMessagesCount},
  };
};

export const fetchMessagesFailure = (error) => {
  return {
    type: FETCH_MESSAGES_FAILURE,
    payload: error,
  };
};

export const resetMessages = (chatId) => ({
  type: RESET_MESSAGES,
  payload: chatId,
});

export const fetchNotificationsRequest = () => {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST,
  };
};

export const fetchNotificationsSuccess = (data) => {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
};

export const fetchNotificationsFailure = (error) => {
  return {
    type: FETCH_NOTIFICATIONS_FAILURE,
    payload: error,
  };
};

export const updateNotificationRequest = (id) => {
  return {
    type: UPDATE_NOTIFICATION_REQUEST,
    payload: id,
  };
};

export const updateNotificationSuccess = (data) => {
  return {
    type: UPDATE_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const updateNotificationFailure = (error) => {
  return {
    type: UPDATE_NOTIFICATION_FAILURE,
    payload: error,
  };
};