import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation
} from "react-router-dom";
import { Provider } from 'react-redux'; 
import store from '../../store/store'; 
import {SocketContext, socket} from '../../context/socket';
import { HelmetProvider } from 'react-helmet-async';
import ChatProvider from "../../context/chatProvider";
import React, { useEffect, useRef, Suspense } from "react";
import { routes } from "../../routes";
import { SEOProvider, useSEO } from "../../context/SEOProvider";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

function RouteWithSEO({ route, props }) {
    const { setSEO } = useSEO();
    const previousSEO = useRef(null);
  
    useEffect(() => {
      if (!previousSEO.current || JSON.stringify(route.seo) !== JSON.stringify(previousSEO.current)) {
        setSEO(route.seo);
        previousSEO.current = route.seo;
      }
    }, [route.seo, setSEO]);
  
    const LazyComponent = React.lazy(route.component);
  
    return (
      <Suspense fallback={<div className="loading-page"></div>}>
        <LazyComponent {...props} />
      </Suspense>
    );
  }

const AppWrapper = () => {
    return (
        <HelmetProvider>
            <SocketContext.Provider value={socket}>
                <Provider store={store}>
                    <SEOProvider>
                        <Router>
                            <ChatProvider>
                                <ScrollToTop />
                                <Routes>
                                {routes.map((route) => (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        element={<RouteWithSEO route={route} props={route.props} />}
                                    />
                                ))}
                                </Routes>
                            </ChatProvider>
                        </Router>
                    </SEOProvider>
                </Provider>
            </SocketContext.Provider>
        </HelmetProvider>
    );
};

export default AppWrapper;
