import { FETCH_MODAL_ACTIVE, FETCH_MODAL_CLOSE } from "../constants/actionTypes"

const initialState = {
    isOpen: false,
    popupType: null,
    className: null,
    props: null
}

export default function ModalReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MODAL_ACTIVE:
            return {
                ...state,
                ...action.payload,
            }
        case FETCH_MODAL_CLOSE:
            return { ...initialState }
        default:
            return state
    }
}
