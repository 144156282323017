import {
    FETCH_ONLINE_DONORS_REQUEST,
    FETCH_ONLINE_DONORS_SUCCESS,
    FETCH_ONLINE_DONORS_FAILURE,
    VIDEO_CONSUMER_REQUEST,
    VIDEO_CONSUMER_SUCCESS,
    VIDEO_CONSUMER_FAILURE,
    SET_CHAT_CONNECTION,
    GET_CHAT_TIME_LEFT_REQUEST,
    GET_CHAT_TIME_LEFT_SUCCESS,
    GET_CHAT_TIME_LEFT_FAILURE,
    SET_CHAT_ACTIVE,
    SET_AVAILABLE_SECONDS
} from '../constants/actionTypes';

export const fetchOnlineDonorsRequest = () => ({
    type: FETCH_ONLINE_DONORS_REQUEST
});

export const fetchOnlineDonorsSuccess = (data) => ({
    type: FETCH_ONLINE_DONORS_SUCCESS,
    payload: data
});

export const fetchOnlineDonorsFailure = (error) => ({
    type: FETCH_ONLINE_DONORS_FAILURE,
    payload: error
});

export const videoConsumerRequest = (payload, callback) => ({
    type: VIDEO_CONSUMER_REQUEST,
    payload,
    callback
  });
  
  export const videoConsumerSuccess = response => ({
    type: VIDEO_CONSUMER_SUCCESS,
    response
  });
  
  export const videoConsumerFailure = error => ({
    type: VIDEO_CONSUMER_FAILURE,
    error
  });

  export const setChatConnection = (isConnected) => ({
    type: SET_CHAT_CONNECTION,
    payload: isConnected,
  });

  export const setChatActive = (roomId) => ({
    type: SET_CHAT_ACTIVE,
    payload: roomId,
  });

  // export const getCurrentChatTimeLeftRequest = (payload, callback) => ({
  //   type: GET_CHAT_TIME_LEFT_REQUEST,
  //   payload,
  //   callback
  // });


export const setAvailableSeconds = (seconds) => ({
  type: SET_AVAILABLE_SECONDS,
  payload: seconds,
});
  
  export const getCurrentChatTimeLeftSuccess = response => ({
    type: GET_CHAT_TIME_LEFT_SUCCESS,
    response
  });
  
  export const getCurrentChatTimeLeftFailure = error => ({
    type: GET_CHAT_TIME_LEFT_FAILURE,
    error
  });
