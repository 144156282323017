// donorReducer.js

import {
  FETCH_DONORS_REQUEST,
  FETCH_DONORS_SUCCESS,
  FETCH_DONORS_FAILURE,
  FETCH_DONOR_BY_ID_REQUEST,
  FETCH_DONOR_BY_ID_SUCCESS,
  FETCH_DONOR_BY_ID_FAILURE,
  FETCH_NEW_DONORS_REQUEST,
  FETCH_NEW_DONORS_SUCCESS,
  FETCH_NEW_DONORS_FAILURE,
  GET_DONOR_PHOTO_FAILURE,
  GET_DONOR_PHOTO_SUCCESS,
  GET_DONOR_PHOTO_REQUEST,
  FETCH_SAVED_DONORS_REQUEST,
  FETCH_SAVED_DONORS_SUCCESS,
  FETCH_SAVED_DONORS_FAILURE,
  FETCH_LIKED_DONORS_REQUEST,
  FETCH_LIKED_DONORS_SUCCESS,
  FETCH_LIKED_DONORS_FAILURE,
  GET_DONOR_VIDEO_REQUEST,
  GET_DONOR_VIDEO_SUCCESS,
  GET_DONOR_VIDEO_FAILURE,
  SET_SURROGACY_DONORS,
  SET_SPERM_DONORS,
} from "../constants/actionTypes";

const initialState = {
  eggsList: null,
  loading: false,
  error: null,
  filterResponse: null,
  singleDonorInfo: null,
  newDonorsList: null,
  savedDonorsList: null,
  likedDonorsList: null,
  donorPhotos: null,
  donorVideos: null,
  surrogacyList: null,
  spermList: null,
};

const donorReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DONORS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        eggsList: null,
        surrogacyList: null,
        spermList: null,
        filterResponse: null,
      };
    case FETCH_DONORS_SUCCESS:
      return {
        ...state,
        loading: false,
        eggsList: action.payload,
        surrogacyList: null,
        spermList: null,
        filterResponse: null,
      };
    case FETCH_DONORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        eggsList: null,
        surrogacyList: null,
        spermList: null,
        filterResponse: null,
      };
    case "SET_FILTER_RESPONSE":
      return {
        ...state,
        filterResponse: action.payload,
        eggsList: null,
        surrogacyList: null,
        spermList: null,
      };
    case SET_SURROGACY_DONORS:
      return {
        ...state,
        surrogacyList: action.payload,
        spermList: null,
        eggsList: null,
      };
    case SET_SPERM_DONORS:
      return { ...state, spermList: action.payload, eggsList: null, surrogacyList: null };
    case FETCH_DONOR_BY_ID_REQUEST:
      return { ...state, loading: true, error: null, singleDonorInfo: null };
    case FETCH_DONOR_BY_ID_SUCCESS:
      return { ...state, loading: false, singleDonorInfo: action.payload };
    case FETCH_DONOR_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        singleDonorInfo: null,
      };
    case FETCH_NEW_DONORS_REQUEST:
      return { ...state, loading: true, error: null, newDonorsList: null };
    case FETCH_NEW_DONORS_SUCCESS:
      return { ...state, loading: false, newDonorsList: action.payload };
    case FETCH_NEW_DONORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        newDonorsList: null,
      };
    case FETCH_SAVED_DONORS_REQUEST:
      return { ...state, loading: true, error: null, savedDonorsList: null };
    case FETCH_SAVED_DONORS_SUCCESS:
      return { ...state, loading: false, savedDonorsList: action.payload };
    case FETCH_SAVED_DONORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        savedDonorsList: null,
      };
    case FETCH_LIKED_DONORS_REQUEST:
      return { ...state, loading: true, error: null, likedDonorsList: null };
    case FETCH_LIKED_DONORS_SUCCESS:
      return { ...state, loading: false, likedDonorsList: action.payload };
    case FETCH_LIKED_DONORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        likedDonorsList: null,
      };
    case GET_DONOR_PHOTO_REQUEST:
      return { ...state, loading: true, error: null, donorPhotos: null };
    case GET_DONOR_PHOTO_SUCCESS:
      return {
        ...state,
        loading: false,
        donorPhotos: {
          ...state.donorPhotos,
          [action.payload.donorId]: action.payload.data.photos,
        },
      };
    case GET_DONOR_PHOTO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        donorPhotos: null,
      };
    case GET_DONOR_VIDEO_REQUEST:
      return { ...state, loading: true, error: null, donorVideos: null };
    case GET_DONOR_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        donorVideos: {
          ...state.donorVideos,
          [action.payload.donorId]: action.payload.data.videos,
        },
      };
    case GET_DONOR_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        donorVideos: null,
      };
    default:
      return state;
  }
};

export default donorReducer;
