import { put, takeLatest, call } from 'redux-saga/effects';
import { 
  submitContactFormSuccess, 
  submitContactFormFailure, 
  updateUserInfoSuccess, 
  updateUserInfoFailure, 
  fetchUserByIdSuccess,
  fetchUserByIdFailure,
  uploadUserAvatarSuccess,
  uploadUserAvatarFailure,
  getClientOrderDonorSuccess,
  getClientOrderDonorFailure,
  getLastOrderCoinsSuccess,
  getLastOrderCoinsFailure,
  getClientBalanceSuccess,
  getClientBalanceFailure
} from '../actions/userActions';
import {
  FETCH_USER_BY_ID_REQUEST, 
  GET_CLIENT_BALANCE_REQUEST, 
  GET_CLIENT_ORDER_DONOR_REQUEST, 
  GET_LAST_ORDER_COINS_REQUEST, 
  SUBMIT_CONTACT_FORM_REQUEST,
  UPDATE_USER_INFO_REQUEST,
  UPLOAD_USER_AVATAR_REQUEST
} from '../constants/actionTypes';
import api from '../api/api';
import instance from '../api/api';

function* submitContactForm(action) {
  try {
    const response = yield call(api.post, '/contactus/create', action.payload);
    yield put(submitContactFormSuccess(response.data));
  } catch (error) {
    yield put(submitContactFormFailure(error));
  }
}

function* fetchUserById(action) {
  try {
    const response = yield call(api.get,`/users/getUser/${action.payload}`);
    yield put(fetchUserByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchUserByIdFailure(error));
  }
}

function* updateUserInfo(action) {
  try {
    const response = yield call(instance.patch, `users/update-profile`, action.payload);
    yield put(updateUserInfoSuccess(response.data));
    if (action.callback) {
      action.callback(response);
    }
  } catch (error) {
    yield put(updateUserInfoFailure(error));
    if (action.callback) {
      action.callback(null, error);
    }
  }
}

function* uploadUserAvatar(action) {
  try {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const config = {
      headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
          "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
      },
  };
    const response = yield call(api.post, `users/uploadAvatar`, action.payload, config);
    yield put(uploadUserAvatarSuccess(response));
    if (action.callback) {
      action.callback(response);
    }
  } catch (error) {
    yield put(uploadUserAvatarFailure(error));
    if (action.callback) {
      action.callback(null, error);
    }
  }
}

function* getClientOrderDonor(action) {
  try {
    const response = yield call(instance.get, `payments/get-user-order-to-donor-package/${action.payload}`);
    yield put(getClientOrderDonorSuccess(response.data));
  } catch (error) {
    yield put(getClientOrderDonorFailure(error));
  }
}

function* getLastOrderCoins(action) {
  try {
    const response = yield call(instance.get, `payments/get-last-package/${action.payload}`);
    yield put(getLastOrderCoinsSuccess(response.data));
  } catch (error) {
    yield put(getLastOrderCoinsFailure(error));
  }
}

function* getClientBalance() {
  try {
    const response = yield call(instance.get,`users/userBalance`);
    yield put(getClientBalanceSuccess(response.data));
  } catch (error) {
    yield put(getClientBalanceFailure(error));
  }
}

function* userSaga() {
  yield takeLatest(SUBMIT_CONTACT_FORM_REQUEST, submitContactForm);
  yield takeLatest(FETCH_USER_BY_ID_REQUEST,fetchUserById);
  yield takeLatest(UPDATE_USER_INFO_REQUEST, updateUserInfo);
  yield takeLatest(UPLOAD_USER_AVATAR_REQUEST, uploadUserAvatar);
  yield takeLatest(GET_CLIENT_ORDER_DONOR_REQUEST, getClientOrderDonor);
  yield takeLatest(GET_LAST_ORDER_COINS_REQUEST, getLastOrderCoins);
  yield takeLatest(GET_CLIENT_BALANCE_REQUEST, getClientBalance);

}

export default userSaga;
