import {
    SUBMIT_CONTACT_FORM_REQUEST,
    SUBMIT_CONTACT_FORM_SUCCESS,
    SUBMIT_CONTACT_FORM_FAILURE, 
    FETCH_USER_BY_ID_REQUEST,
    UPDATE_USER_INFO_REQUEST,
    UPDATE_USER_INFO_SUCCESS,
    UPDATE_USER_INFO_FAILURE,
    FETCH_USER_BY_ID_SUCCESS,
    FETCH_USER_FAILURE,
    UPLOAD_USER_AVATAR_REQUEST,
    UPLOAD_USER_AVATAR_SUCCESS,
    UPLOAD_USER_AVATAR_FAILURE,
    GET_CLIENT_ORDER_DONOR_REQUEST,
    GET_CLIENT_ORDER_DONOR_SUCCESS,
    GET_CLIENT_ORDER_DONOR_FAILURE,
    GET_LAST_ORDER_COINS_REQUEST,
    GET_LAST_ORDER_COINS_SUCCESS,
    GET_LAST_ORDER_COINS_FAILURE,
    GET_USER_UNREADED_MESS_REQUEST,
    GET_CLIENT_BALANCE_SUCCESS,
    GET_CLIENT_BALANCE_REQUEST,
    GET_CLIENT_BALANCE_FAILURE,
} from '../constants/actionTypes';

  export const submitContactFormRequest = (formData) => ({
    type: SUBMIT_CONTACT_FORM_REQUEST,
    payload: formData,
  });

export const fetchUserRequestAcction = (user) => ({
    type: FETCH_USER_BY_ID_REQUEST,
    payload: user,
});

export const fetchUserByIdSuccess = (user) => ({
  type: FETCH_USER_BY_ID_SUCCESS,
  payload: user,
});

export const fetchUserByIdFailure = (error) => ({
  type: FETCH_USER_FAILURE,
  payload: error,
});
  export const submitContactFormSuccess = () => ({
    type: SUBMIT_CONTACT_FORM_SUCCESS,
  });
  
  export const submitContactFormFailure = (error) => ({
    type: SUBMIT_CONTACT_FORM_FAILURE,
    payload: error,
  });

  export const updateUserInfoRequest = (payload, callback) => ({
    type: UPDATE_USER_INFO_REQUEST,
    payload, callback
  });
  
  export const updateUserInfoSuccess = (data) => ({
    type: UPDATE_USER_INFO_SUCCESS,
    payload: data,
  });
  
  export const updateUserInfoFailure = (error) => ({
    type: UPDATE_USER_INFO_FAILURE,
    payload: error,
  });

  export const uploadUserAvatarRequest = (payload, callback) => ({
    type: UPLOAD_USER_AVATAR_REQUEST,
    payload, callback,
  });
  
  export const uploadUserAvatarSuccess = (data) => ({
    type: UPLOAD_USER_AVATAR_SUCCESS,
    payload: data,
  });
  
  export const uploadUserAvatarFailure = (error) => ({
    type: UPLOAD_USER_AVATAR_FAILURE,
    payload: error,
  });


export const getClientOrderDonorRequest = (userId) => ({
  type: GET_CLIENT_ORDER_DONOR_REQUEST,
  payload: userId
});

export const getClientOrderDonorSuccess = (data) => ({
  type: GET_CLIENT_ORDER_DONOR_SUCCESS,
  payload: data,
});

export const getClientOrderDonorFailure = (error) => ({
  type: GET_CLIENT_ORDER_DONOR_FAILURE,
  payload: error,
});

export const getLastOrderCoinsRequest = (userId) => ({
  type: GET_LAST_ORDER_COINS_REQUEST,
  payload: userId
});

export const getLastOrderCoinsSuccess = (data) => ({
  type: GET_LAST_ORDER_COINS_SUCCESS,
  payload: data,
});

export const getLastOrderCoinsFailure = (error) => ({
  type: GET_LAST_ORDER_COINS_FAILURE,
  payload: error,
});

export const getClientBalanceRequest = (userId) => ({
  type: GET_CLIENT_BALANCE_REQUEST,
  payload: userId
});

export const getClientBalanceSuccess = (data) => ({
  type: GET_CLIENT_BALANCE_SUCCESS,
  payload: data,
});

export const getClientBalanceFailure = (error) => ({
  type: GET_CLIENT_BALANCE_FAILURE,
  payload: error,
});

export const getUserUnreadedRequest = (res) => ({
  type: GET_USER_UNREADED_MESS_REQUEST,
  payload: res
});