import {
    FETCH_MESSAGES_FOR_EMAIL_REQUEST,
    FETCH_MESSAGES_FOR_EMAIL_SUCCESS, FETCH_MESSAGES_FOR_EMAIL_FAILURE, SEND_MAIL_REQUEST, SEND_MAIL_SUCCESS, SEND_MAIL_FAILURE, SELECT_DONOR_FOR_EMAIL, SEND_CONVERSATION_REQUEST, RECEIVE_CONVERSATION_RESPONSE, CONVERSATION_ERROR, GET_EMAILS_SENT_REQUEST, GET_EMAILS_SENT_SUCCESS, GET_EMAILS_SENT_FAILURE, FETCH_STAR_REQUEST, FETCH_STAR_SUCCESS, FETCH_STAR_FAILURE, DELETE_CONVERSATION_REQUEST, DELETE_CONVERSATION_SUCCESS, DELETE_CONVERSATION_FAILURE, ADD_EMAIL_LABEL_SUCCESS, ADD_EMAIL_LABEL_FAILURE, ADD_EMAIL_LABEL_REQUEST, GET_EMAILS_STARRED_REQUEST, GET_EMAILS_STARRED_SUCCESS, GET_EMAILS_STARRED_FAILURE, GET_EMAILS_DELETED_REQUEST, GET_EMAILS_DELETED_SUCCESS, GET_EMAILS_DELETED_FAILURE, DELETE_EMAILS_LABELS_REQUEST, DELETE_EMAILS_LABELS_SUCCESS, DELETE_EMAILS_LABELS_FAILURE, FETCH_EMAILS_SUCCESS,FETCH_EMAILS_FAILURE,FETCH_EMAILS_REQUEST,UPDATE_EMAIL_NOTIFICATION_SUCCESS,
    UPDATE_EMAIL_NOTIFICATION_REQUEST,UPDATE_EMAIL_NOTIFICATION_FAILURE
} from "../constants/actionTypes";

export const fetchEmailsRequest = (payload, callback) => ({
    type: FETCH_EMAILS_REQUEST,
    payload,
    callback
});

export const fetchEmailsSuccess = (emails) => ({
    type: FETCH_EMAILS_SUCCESS,
    payload: emails,
});

export const fetchEmailsFailure = (error) => ({
    type: FETCH_EMAILS_FAILURE,
    payload: error,
});

export const fetchMessagesForEmailRequest = (page, limit, mailId) => ({
    type: FETCH_MESSAGES_FOR_EMAIL_REQUEST,
    payload:{page, limit, mailId}
});

export const fetchMessagesForEmailSuccess = (messages) => ({
    type: FETCH_MESSAGES_FOR_EMAIL_SUCCESS,
    payload: messages,
});

export const fetchMessagesForEmailFailure = (error) => ({
    type: FETCH_MESSAGES_FOR_EMAIL_FAILURE,
    payload: error,
});

export const fetchStarRequest = (payload, callback) => ({
  type: FETCH_STAR_REQUEST,
  payload,
  callback,
});

export const fetchStarSuccess = (response) => ({
  type: FETCH_STAR_SUCCESS,
  response
});

export const fetchStarFailure = (error) => ({
  type: FETCH_STAR_FAILURE,
  error
});

export const sendMailRequest = (payload, callback) => ({
    type: SEND_MAIL_REQUEST,
    payload,
    callback,
  });
  
  export const sendMailSuccess = (response) => ({
    type: SEND_MAIL_SUCCESS,
    response,
  });
  
  export const sendMailFailure = (error) => ({
    type: SEND_MAIL_FAILURE,
    error,
  });

  export const updateEmailNotificationRequest = (mailId) => ({
    type: UPDATE_EMAIL_NOTIFICATION_REQUEST,
    mailId,
  });
  
  export const updateEmailNotificationSuccess = (mailId) => ({
    type: UPDATE_EMAIL_NOTIFICATION_SUCCESS,
    mailId,
  });
  
  export const updateEmailNotificationFailure = (error) => ({
    type: UPDATE_EMAIL_NOTIFICATION_FAILURE,
    error,
  });

  export const selectDonorForEmail = (donor) => ({
    type: SELECT_DONOR_FOR_EMAIL,
    payload: { donor },
  });

  export const getEmailsSentRequest = (payload, callback) => ({
    type: GET_EMAILS_SENT_REQUEST,
    payload,
    callback
  });
  
  export const getEmailsSentSuccess = (emailsSent) => ({
    type: GET_EMAILS_SENT_SUCCESS,
    payload: emailsSent,
  });
  
  export const getEmailsSentFailure = (error) => ({
    type: GET_EMAILS_SENT_FAILURE,
    payload: error,
  });

  export const deleteConversationRequest = (payload, callback) => ({
    type: DELETE_CONVERSATION_REQUEST,
    payload,
    callback,
  });
  
  export const deleteConversationSuccess = () => ({
    type: DELETE_CONVERSATION_SUCCESS
  });
  
  export const deleteConversationFailure = (error) => ({
    type: DELETE_CONVERSATION_FAILURE,
    payload: error
  });

  export const addEmailLabelSuccess = (data) => ({
    type: ADD_EMAIL_LABEL_SUCCESS,
    payload: data,
  });

  export const addEmailLabelFailure = (error) => ({
    type: ADD_EMAIL_LABEL_FAILURE,
    payload: error,
  });

  export const addEmailLabelRequest = (payload, callback,) => ({
    type: ADD_EMAIL_LABEL_REQUEST,
    payload,
    callback,
});

export const getEmailsStarredRequest = (payload, callback) => ({
  type: GET_EMAILS_STARRED_REQUEST,
  payload,
  callback
});

export const getEmailsStarredSuccess = (emailsSent) => ({
  type: GET_EMAILS_STARRED_SUCCESS,
  payload: emailsSent,
});

export const getEmailsStarredFailure = (error) => ({
  type: GET_EMAILS_STARRED_FAILURE,
  payload: error,
});

export const getEmailsDeletedRequest = (payload, callback) => ({
  type: GET_EMAILS_DELETED_REQUEST,
  payload,
  callback
});

export const getEmailsDeletedSuccess = (emailsSent) => ({
  type: GET_EMAILS_DELETED_SUCCESS,
  payload: emailsSent,
});

export const getEmailsDeletedFailure = (error) => ({
  type: GET_EMAILS_DELETED_FAILURE,
  payload: error,
});

export const deleteEmailsLabelsRequest = (payload, callback) => ({
  type: DELETE_EMAILS_LABELS_REQUEST,
  payload,
  callback,
});

export const deleteEmailsLabelsSuccess = () => ({
  type: DELETE_EMAILS_LABELS_SUCCESS
});

export const deleteEmailsLabelsFailure = (error) => ({
  type: DELETE_EMAILS_LABELS_FAILURE,
  payload: error
});