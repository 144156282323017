import { call, put, takeLatest } from 'redux-saga/effects';
import instance from "../api/api";
import { FETCH_ONLINE_DONORS_REQUEST, GET_CHAT_TIME_LEFT_REQUEST } from '../constants/actionTypes';
import {
    fetchOnlineDonorsSuccess,
    fetchOnlineDonorsFailure,
    videoConsumerSuccess,
    videoConsumerFailure,
    getCurrentChatTimeLeftSuccess
  } from '../actions/videoChatActions';

function* fetchOnlineDonorsSaga() {
  try {
    const response = yield call(instance.get, 'donors/onlineDonors');
    yield put(fetchOnlineDonorsSuccess(response.data));
  } catch (error) {
    yield put(fetchOnlineDonorsFailure(error));
  }
}

function* getCurrentChatTimeLeft(action) {
  try {
    const { payload } = action;
    const response = yield call(instance.patch, 'conversation/calculateAvailableTime', payload);
    yield put(getCurrentChatTimeLeftSuccess(response.data.count));
    if (action.callback) {
      action.callback(response.data.count);
    }
  } catch (error) {
    yield put(getCurrentChatTimeLeftFailure(error));
    if (action.callback) {
      action.callback(error);
    }
  }
}

function* videoChatSaga() {
  yield takeLatest(FETCH_ONLINE_DONORS_REQUEST, fetchOnlineDonorsSaga);
  yield takeLatest(GET_CHAT_TIME_LEFT_REQUEST, getCurrentChatTimeLeft);
}

export default videoChatSaga