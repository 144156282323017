import { CONVERSATION_ERROR, GET_COINS_PACKAGES_FAILURE, GET_COINS_PACKAGES_REQUEST, GET_COINS_PACKAGES_SUCCESS, GET_DONOR_PACKAGES_FAILURE, GET_DONOR_PACKAGES_REQUEST, GET_DONOR_PACKAGES_SUCCESS, HIDE_MENU, RECEIVE_CONVERSATION_RESPONSE, SEND_CONVERSATION_REQUEST, SEND_ORDER_DONOR_FAILURE, SEND_ORDER_DONOR_REQUEST, SEND_ORDER_DONOR_SUCCESS, SEND_PAYMENTS_FAILURE, SEND_PAYMENTS_REQUEST, SEND_PAYMENTS_SUCCESS, SET_IS_SHOW_MODAL, SHOW_MENU, SOCKET_CONNECTION } from "../constants/actionTypes";

export const hideMenu = () => ({
    type: HIDE_MENU
});

export const showMenu = () => ({
    type: SHOW_MENU
});

export const sendConversationRequest = (payload, callback) => ({
    type: SEND_CONVERSATION_REQUEST,
    payload,
    callback,
});

export const receiveConversationResponse = (response) => ({
    type: RECEIVE_CONVERSATION_RESPONSE,
    response,
});

export const handleConversationError = (error) => ({
    type: CONVERSATION_ERROR,
    error,
});

export const setSocketConnection = (res) => ({
    type: SOCKET_CONNECTION,
    payload: res,
});

export const getCoinsPackagesRequest = () => ({
    type: GET_COINS_PACKAGES_REQUEST,
});

export const getCoinsPackagesSuccess = (data) => ({
    type: GET_COINS_PACKAGES_SUCCESS,
    payload: data,
});

export const getCoinsPackagesFailure = (error) => ({
    type: GET_COINS_PACKAGES_FAILURE,
    payload: error,
});

export const getDonorPackagesRequest = () => ({
    type: GET_DONOR_PACKAGES_REQUEST,
});

export const getDonorPackagesSuccess = (data) => ({
    type: GET_DONOR_PACKAGES_SUCCESS,
    payload: data,
});

export const getDonorPackagesFailure = (error) => ({
    type: GET_DONOR_PACKAGES_FAILURE,
    payload: error,
});

export const sendPaymentsRequest = (payload, callback) => ({
    type: SEND_PAYMENTS_REQUEST,
    payload, callback
});

export const sendPaymentsSuccess = (data) => ({
    type: SEND_PAYMENTS_SUCCESS,
    payload: data,
});

export const sendPaymentsFailure = (error) => ({
    type: SEND_PAYMENTS_FAILURE,
    payload: error,
});

export const sendOrderDonorRequest = (payload, callback) => ({
    type: SEND_ORDER_DONOR_REQUEST,
    payload, callback
});

export const sendOrderDonorSuccess = (data) => ({
    type: SEND_ORDER_DONOR_SUCCESS,
    payload: data,
});

export const sendOrderDonorFailure = (error) => ({
    type: SEND_ORDER_DONOR_FAILURE,
    payload: error,
});

export const setIsShowModal = (payload) => ({
    type: SET_IS_SHOW_MODAL, 
    payload
}) 