import {
    FETCH_ONLINE_DONORS_REQUEST,
    FETCH_ONLINE_DONORS_SUCCESS,
    FETCH_ONLINE_DONORS_FAILURE,
    SET_CHAT_CONNECTION,
    SET_CHAT_ACTIVE,
    SET_AVAILABLE_SECONDS
  } from '../constants/actionTypes';

const initialState = {
    onlineDonors: null,
    loading: false,
    error: null,
    chatConnection: false,
    activeChat: null,
    availableSeconds: 0,
};

const videoChatReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ONLINE_DONORS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_ONLINE_DONORS_SUCCESS:
            return {
                ...state,
                onlineDonors: action.payload,
                loading: false,
                error: null
            };
        case FETCH_ONLINE_DONORS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case SET_CHAT_CONNECTION:
            return {
                ...state,
                chatConnection: action.payload,
            };
        case SET_CHAT_ACTIVE:
            return {
                ...state,
                activeChat: action.payload,
            };
        case SET_AVAILABLE_SECONDS:
            return {
                ...state,
                availableSeconds: action.payload,
            };
        default:
            return state;
    }
};

export default videoChatReducer;