import { FETCH_MODAL_ACTIVE, FETCH_MODAL_CLOSE, FETCH_POPUP } from '../constants/actionTypes'

export const setPopupActive = (payload) => {
    return { type: FETCH_MODAL_ACTIVE, payload }
}

export const setPopupClose = () => {
    return { type: FETCH_MODAL_CLOSE }
}

export const fetchPopup = (data) => {
    return { type: FETCH_POPUP, payload: data };
};
