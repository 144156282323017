import {
    FETCH_EMAILS_REQUEST, FETCH_EMAILS_SUCCESS, FETCH_EMAILS_FAILURE,
    FETCH_MESSAGES_FOR_EMAIL_SUCCESS, FETCH_MESSAGES_FOR_EMAIL_FAILURE, FETCH_MESSAGES_FOR_EMAIL_REQUEST, SELECT_DONOR_FOR_EMAIL, GET_EMAILS_SENT_REQUEST, GET_EMAILS_SENT_SUCCESS, GET_EMAILS_SENT_FAILURE, GET_EMAILS_DELETED_REQUEST, GET_EMAILS_DELETED_SUCCESS, GET_EMAILS_DELETED_FAILURE, GET_EMAILS_STARRED_REQUEST, GET_EMAILS_STARRED_SUCCESS, GET_EMAILS_STARRED_FAILURE,
} from "../constants/actionTypes";

const initialState = {
    loading: false,
    emails: [],
    messages: null,
    error: null,
    selectedDonor: null,
    emailsSent: null,
    emailsStarred: null,
    emailsDeleted: null,
};

const emailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EMAILS_REQUEST:
            return { ...state, loading: true };
        case FETCH_EMAILS_SUCCESS:
            return { ...state, loading: false, emails: action.payload };
        case FETCH_EMAILS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case FETCH_MESSAGES_FOR_EMAIL_REQUEST:
            return { ...state, loading: true }; // Початок завантаження повідомлень
        case FETCH_MESSAGES_FOR_EMAIL_SUCCESS:
            return { ...state, loading: false, messages: action.payload }; // Успішне завантаження повідомлень
        case FETCH_MESSAGES_FOR_EMAIL_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case SELECT_DONOR_FOR_EMAIL:
            return {
                ...state,
                selectedDonor: action.payload.donor,
            };
        case GET_EMAILS_SENT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_EMAILS_SENT_SUCCESS:
            return {
                ...state,
                emailsSent: action.payload,
                loading: false,
                error: null,
            };
        case GET_EMAILS_SENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_EMAILS_STARRED_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_EMAILS_STARRED_SUCCESS:
            return {
                ...state,
                emailsStarred: action.payload,
                loading: false,
                error: null,
            };
        case GET_EMAILS_STARRED_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_EMAILS_DELETED_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_EMAILS_DELETED_SUCCESS:
            return {
                ...state,
                emailsDeleted: action.payload,
                loading: false,
                error: null,
            };
        case GET_EMAILS_DELETED_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default emailsReducer;
