import { GET_COINS_PACKAGES_FAILURE, GET_COINS_PACKAGES_REQUEST, GET_COINS_PACKAGES_SUCCESS, GET_DONOR_PACKAGES_FAILURE, GET_DONOR_PACKAGES_REQUEST, GET_DONOR_PACKAGES_SUCCESS, HIDE_MENU, SET_IS_SHOW_MODAL, SHOW_MENU, SOCKET_CONNECTION } from "../constants/actionTypes";


const initialState = {
  hideMenu: false,
  socketConnected: false,
  coinsPacks: null,
  donorPacks: null,
  isShowModal: false,
  loading: false,
  error: null,
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case HIDE_MENU:
      return {
        ...state,
        hideMenu: true
      };
    case SHOW_MENU:
      return {
        ...state,
        hideMenu: false
      };
    case SOCKET_CONNECTION:
      return {
        ...state,
        socketConnected: action.payload
      };
    case GET_COINS_PACKAGES_REQUEST:
      return { ...state, loading: true, error: null };

    case GET_COINS_PACKAGES_SUCCESS:
      return { ...state, loading: false, coinsPacks: action.payload, error: null };

    case GET_COINS_PACKAGES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case GET_DONOR_PACKAGES_REQUEST:
      return { ...state, loading: true, error: null };

    case GET_DONOR_PACKAGES_SUCCESS:
      return { ...state, loading: false, donorPacks: action.payload, error: null };

    case GET_DONOR_PACKAGES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case SET_IS_SHOW_MODAL:
      return {
          ...state,
          isShowModal: action.payload
      }
    default:
      return state;
  }
};

export default globalReducer;
