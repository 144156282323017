import { put, takeLatest, call } from 'redux-saga/effects';
import { loginSuccess, loginFailure, logoutFailure, logoutSuccess, registrationSuccess, registrationFailure, resetPasswordSuccess, resetPasswordFailure } from '../actions/authActions';
import { LOGIN_REQUEST, LOGOUT_REQUEST, REGISTRATION_REQUEST, RESET_PASSWORD_REQUEST } from '../constants/actionTypes';
// import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import instance from '../api/api';

function saveUserInfoToLocalStorage(user, token) {
    // Получаем существующую информацию из localStorage
    const existingUserInfo = JSON.parse(localStorage.getItem("userInfo")) || {};

    // Объединяем существующую информацию с новой
    const updatedUserInfo = { ...existingUserInfo, ...user, token };

    // Сохраняем обновленную информацию в localStorage
    localStorage.setItem("userInfo", JSON.stringify(updatedUserInfo));
}

function* login(action) {
    const { onSuccessRedirect } = action.payload;
    try {
        const response = yield call(instance.post, 'auth/login', action.payload);
        if (response.data.token) {
            // Сохраняем токен в куки с именем 'token'
            // const expirationDate = new Date();
            // expirationDate.setDate(expirationDate.getDate() + 7); // Например, срок действия 7 дней
            // Cookies.set('token', response.data.token, { expires: expirationDate, SameSite: 'None', Secure: true });

            // Добавляем информацию пользователя в localStorage
            const user = jwtDecode(response.data.token);
            saveUserInfoToLocalStorage(user, response.data.token);
            
            if (onSuccessRedirect) {
                onSuccessRedirect('/'); 
            }
            
        }
        yield put(loginSuccess(response.data));
    } catch (error) {
        yield put(loginFailure(error.response.data));
    }
}

function* registration(action) {
    const { onSuccess } = action.payload;
    try {
        const response = yield call(instance.post, 'auth/signup', action.payload);
        if (response) {
            if (onSuccess) {
                onSuccess(); 
            }
        }
        yield put(registrationSuccess(response.data));
    } catch (error) {
        yield put(registrationFailure(error.response.data));
    }
}

function* logoutSaga(action) {
    const { onSuccessRedirect } = action.payload;
    try {
        const response = yield call(instance.patch, `auth/logout`);
  
        yield put(logoutSuccess(response.data));
        if (onSuccessRedirect) {
            onSuccessRedirect('/login'); 
    }
  
    } catch (error) {
        yield put(logoutFailure(error));
    }
}

function* resetPassword(action) {
    const { onSuccess } = action.payload;
    try {
        const response = yield call(instance.post, 'auth/reset-password', action.payload);
        if (response) {
            if (onSuccess) {
                onSuccess(); 
            }
        }
        yield put(resetPasswordSuccess(response.data));
    } catch (error) {
        yield put(resetPasswordFailure(error.response.data));
    }
}

function* authSaga() {
    yield takeLatest(LOGIN_REQUEST, login);
    yield takeLatest(LOGOUT_REQUEST, logoutSaga);
    yield takeLatest(REGISTRATION_REQUEST, registration);
    yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
}

export default authSaga;
