import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE, REGISTRATION_REQUEST, REGISTRATION_SUCCESS, REGISTRATION_FAILURE, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE } from '../constants/actionTypes';

export const loginRequest = (credentials) => ({
    type: LOGIN_REQUEST,
    payload: credentials,
});

export const loginSuccess = (user) => ({
    type: LOGIN_SUCCESS,
    payload: user,
});

export const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    payload: error,
});

export const registrationRequest = (credentials) => ({
    type: REGISTRATION_REQUEST,
    payload: credentials,
});

export const registrationSuccess = (user) => ({
    type: REGISTRATION_SUCCESS,
    payload: user,
});

export const registrationFailure = (error) => ({
    type: REGISTRATION_FAILURE,
    payload: error,
});

export const logoutRequest = (credentials) => ({
    type: LOGOUT_REQUEST,
    payload: credentials,
});
  
export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS
});
  
export const logoutFailure = (error) => ({
    type: LOGOUT_FAILURE,
    error
});

export const resetPasswordRequest = (credentials) => ({
    type: RESET_PASSWORD_REQUEST,
    payload: credentials,
});

export const resetPasswordSuccess = (user) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: user,
});

export const resetPasswordFailure = (error) => ({
    type: RESET_PASSWORD_FAILURE,
    payload: error,
});
